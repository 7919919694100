import React, { Component } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Box } from '@mui/material';
import siteAvailability from '../assets/siteAvaibility.png';
import '../CSS/SiteAvailability.css';
import { getSiteAvailabilityData, getSiteRigDiscountExtra } from '../utils/OpenCampground_Database_Calls'
import { format } from 'date-fns';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class SiteAvailability extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spaces: [],
            reservations: [],
            dates: [],
            sites: [],
            todaysDate: format(new Date(), 'yyyy-MM-dd'),
            allSpaces: [],
            availableSitesToday: ''
        };
    }

    async componentDidMount() {
        let siteRigDiscountExtra = await getSiteRigDiscountExtra(this.props.user, this.props.serverName);
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 2);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedTomorrow = format(tomorrow, 'yyyy-MM-dd');
        let getSiteAvailabilityDataResponse = await getSiteAvailabilityData(this.props.user, this.props.serverName, startDate.toISOString().split('T')[0], this.state.todaysDate, formattedTomorrow)
        const dates = [];
        let endDate = new Date();
        endDate.setFullYear(startDate.getFullYear() + 1);
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            let formattedDate = d.toISOString().split('T')[0];
            dates.push(formattedDate);
        }
        this.setState({
            spaces: getSiteAvailabilityDataResponse.body[1],
            allSpaces: getSiteAvailabilityDataResponse.body[1],
            reservations: getSiteAvailabilityDataResponse.body[0],
            dates: dates,
            sites: siteRigDiscountExtra[0],
            availableSitesToday: getSiteAvailabilityDataResponse.body[2][0].available_spaces_count
        });
    }

    isReserved(space, date) {
        const today = new Date();
        today.setDate(today.getDate() - 3);
        const currentDate = new Date(date).toISOString().split('T')[0];
        for (let res of this.state.reservations) {
            if (
                res.space === space &&
                new Date(date) >= new Date(res.startdate) &&
                new Date(date) <= new Date(res.enddate)
            ) {
                const isFirstDay = currentDate === res.startdate.split('T')[0];
                const isLastDay = currentDate === res.enddate.split('T')[0];
                let startedReservation = currentDate === today.toISOString().split('T')[0] && new Date(res.startdate).toISOString().split('T')[0] < today.toISOString().split('T')[0];
                return { camper: res.camper, isFirstDay, isLastDay, startedReservation, id: res.id };
            }
        }
        return null;
    }

    filterChange = async (selectedSite) => {
        let filteredSpaces
        if (selectedSite === 'ALL') {
            filteredSpaces = this.state.allSpaces
        } else {
            filteredSpaces = this.state.allSpaces.filter(space => space.siteType === selectedSite)
        }
        this.setState({
            spaces: filteredSpaces
        })
    }

    render() {
        const { spaces, dates } = this.state;

        return (
            <ThemeProvider theme={theme}>
                <Grid className='siteAvailability'>
                    <Box className='infoBox'>
                        <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                        <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                    </Box>

                    <div className='box' style={{ alignItems: 'flex-start' }}>
                        <div className='divStyle'>
                            <img src={siteAvailability} alt="siteAvailability" className='buttonImage' />
                            <Typography className='siteAvailabilitytypography'>
                                Site Availability
                            </Typography>
                        </div>
                    </div>

                    <Box className='tableContainer'>
                        <div style={{ border: '1px solid #979797', margin: "40px 20px 27px", padding: "0 0 0 40px", borderRadius: "1000px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p style={{ fontSize: '18px', fontWeight: "700", margin: "0px" }}>Available Sites: {this.state.availableSitesToday}</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <select onChange={(e) => this.filterChange(e.target.value)}>
                                        <option value="ALL">All</option>
                                        {this.state.sites.map((site, index) => (
                                            <option key={index} value={site.name}>
                                                {site.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="stickyFirstColumn">Site Number</TableCell>
                                    {dates.map((date, idx) => (
                                        <TableCell key={idx} className="stickyHeader">
                                            {date.split('-')[1]}/{date.split('-')[2]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                                {spaces.map((spaceObj, idx) => {
                                    const space = spaceObj.name;
                                    const reservationsMap = new Map();
                                    dates.forEach((date) => {
                                        const reservation = this.isReserved(space, date);
                                        if (reservation) {
                                            reservationsMap.set(date, reservation);
                                        }
                                    });
                                    let mergedReservations = [];
                                    let i = 0;

                                    while (i < dates.length) {
                                        const date = dates[i];
                                        const reservation = reservationsMap.get(date);

                                        if (reservation) {
                                            let colSpan = 1;
                                            while (reservationsMap.get(dates[i + colSpan])?.id === reservation.id) {
                                                colSpan++;
                                            }
                                            mergedReservations.push({
                                                reservation,
                                                colSpan
                                            });
                                            i += colSpan;
                                        } else {
                                            mergedReservations.push(null);
                                            i++;
                                        }
                                    }

                                    return (
                                        <TableRow key={idx}>
                                            <TableCell className="stickyFirstColumn">{space}</TableCell>

                                            {mergedReservations.map((entry, i) =>
                                                entry ? (
                                                    <TableCell
                                                        key={i}
                                                        className="dateCell"
                                                        style={{
                                                            backgroundColor: idx % 2 === 1 ? "#98B2A5" : '#8BD9CC',
                                                            textAlign: 'center',
                                                            borderTopLeftRadius: mergedReservations.isFirstDay ? "0px" : "30px",
                                                            borderBottomLeftRadius: mergedReservations.isFirstDay ? "0px" : "30px",
                                                            borderTopRightRadius: mergedReservations.isLastDay ? "0px" : "30px",
                                                            borderBottomRightRadius: mergedReservations.isLastDay ? "0px" : "30px",
                                                        }}
                                                        colSpan={entry.colSpan}
                                                    >
                                                        {entry.reservation.camper} - {entry.reservation.id}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        key={i}
                                                        className="dateCell"
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            textAlign: 'center',
                                                            border:  "none",
                                                        }}
                                                    />
                                                )
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
            </ThemeProvider>
        );
    }
}
export default SiteAvailability;