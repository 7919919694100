import './App.css';
import Login from './components/Login';
import RemoteLangingPage from './components/RemoteLangingPage';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const trackingIDs = {
  movin: "G-CRYLHJ7R35",
  opencampground: "G-LP4CMF89TW",
  bearcamp: "G-JBQS4SP2EG",
  bigpinesrv: "G-4J342QD5BQ",
  fortlions: "G-H88ED38MVH",
  littlefieldbeaches: "G-NWDWCC69VF",
  tuckerlake: "G-T7HSWJH97V",
  annacountryrv: "G-S49Z1JT0K4",
  becampground: "G-5JBF1MV74D",
  bigstarrv: "G-JF863QFB6Q",
  bpoe1603: "G-PQSEVLYQRP",
  bpoe2504: "G-NS7NLHWYPD",
  brooks: "G-3SQLHF2967",
  cornerstone: "G-EZ6TLSZ2JB",
  deepcreek: "G-X4M2XWS3XK",
  doublej: "G-1J2MHS6Y2T",
};

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/manager") {
      const urlParams = new URLSearchParams(window.location.search);
      const serverName = urlParams.get("serverName");
      const trackingId = trackingIDs[serverName];

      if (trackingId) {
        const existingScript = document.querySelector(`script[src*="googletagmanager.com/gtag/js?id=${trackingId}"]`);
        if (existingScript) return;

        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }

        gtag("js", new Date());
        gtag("config", trackingId);
      }
    }
  }, [location.pathname]);
};

const AppContent = () => {
  useGoogleAnalytics();
  return (
    <div className="App">
      <Switch>
        <Route path='/manager' component={Login} />
        <Route path='/remote' component={RemoteLangingPage} />
      </Switch>
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
