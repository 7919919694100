import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import '../CSS/Transactions.css';
import {
    getTransactions, updateTransaction, cardConnectInquire,
    cardConnectVoid, cardConnectRefund, getIntegrations
} from '../utils/OpenCampground_Database_Calls';
import { getPayment, returnVoid, partialRefund } from '../utils/MxMerchant_Services'
import SearchBar from "material-ui-search-bar";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Select, MenuItem } from '@mui/material';
import Print from './Print';
import transactions from '../assets/transactions.png'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = theme => ({
    circularProgress: {
        position: 'absolute',
        left: '55%',
        top: '50%',
        color: '#2dcd7a'
    },
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    },
    printPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
        width: '5000px'
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class Transactions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allTransactions: [],
            transitions: [],
            currentPage: '1',
            searchValue: '',
            searchBy: 'id',
            open: false,
            confirm: false,
            popupText: '',
            popupTitle: '',
            transactionToHandel: "",
            confirmationOpen: false,
            confirmationText: '',
            confirmPoputTitle: '',
            paymentIntegration: [],
            noTransactions: false,
            printOpen: false,
            printItem: {},

            referenceNumberToVoidorReturn: '',
            transactionVoidable: false,
            fullRefund: null,
            partialRefund: null,
            partialRefundAmt: '',
            campPayId: '',
            noPastTransactions: false
        };
    }

    async componentDidMount() {
        this.getTransactions()
        let paymentIntegration = await getIntegrations(this.props.user, this.props.serverName)
        this.setState({
            paymentIntegration: paymentIntegration
        })
    }

    getTransactions = async () => {
        let listOfTransactions = await getTransactions(this.props.user, this.props.serverName);
        console.log("List Of Transactions: ", listOfTransactions)
        this.setState({
            allTransactions: listOfTransactions,
            transitions: listOfTransactions,
            noPastTransactions: listOfTransactions.length === 0
        })
    }

    getTransactionsByReservationNumber = async (value) => {
        const searchValue = value.toString();
        const filteredTransactions = this.state.allTransactions.filter(transaction => {
            const reservationId = (transaction.reservation_id || '').toString();
            return reservationId.includes(searchValue);
        });
        if (filteredTransactions.length < 1) {
            this.setState({
                value: '',
                noTransactions: true,
            });
        } else {
            this.setState({
                value: '',
                transitions: filteredTransactions,
                noTransactions: false
            });
        }
    }

    getTransactionsByLast4 = async (value) => {
        const searchValue = value.toString();
        const filteredTransactions = this.state.allTransactions.filter(transaction => {
            const reservationId = (transaction.credit_card_no || '').toString();
            return reservationId.includes(searchValue);
        });
        if (filteredTransactions.length < 1) {
            this.setState({
                value: '',
                noTransactions: true,
            });
        } else {
            this.setState({
                value: '',
                transitions: filteredTransactions,
                noTransactions: false
            });
        }
    }

    displayAllTransactions = async () => {
        this.setState({
            transitions: this.state.allTransactions,
        })
    }

    refundVoid = async (item) => {
        console.log("item: ", item)
        let authorizationCode = item.memo;
        let referenceNumber = authorizationCode.match(/\d+/)[0];
        let popupText
        let campPayId = ''
        let transactionVoidable
        let popupTitle
        if (this.state.paymentIntegration.name === 'CampPay') {
            let getTransactionData = await getPayment(this.state.paymentIntegration.cp_merchantid, referenceNumber)
            console.log("getTransactionData: ",getTransactionData)
            campPayId = getTransactionData.records[0].id
            if (getTransactionData.records[0].status === 'Settled') {
                popupText = 'Do you want to refund a partial amount or the full amount of $' + item.amount + '?'
                transactionVoidable = false
                popupTitle = "Refund Transaction"
            } else {
                popupText = 'Void Transaction for amount $' + item.amount
                transactionVoidable = true
                popupTitle = "Void Transaction"
            }
        } else {
            let inquireResponse = await cardConnectInquire(this.props.user, "177000000077", referenceNumber)
            if (inquireResponse.voidable === 'Y') {
                popupText = 'Void Transaction for amount $' + item.amount
                transactionVoidable = true
                popupTitle = "Void Transaction"
            } else {
                popupText = 'Do you want to refund a partial amount or the full amount of $' + item.amount + '?'
                transactionVoidable = false
                popupTitle = "Refund Transaction"
            }
        }
        this.setState({
            open: true,
            transactionToHandel: item,
            popupText: popupText,
            referenceNumberToVoidorReturn: referenceNumber,
            campPayId: campPayId,
            transactionVoidable: transactionVoidable,
            popupTitle: popupTitle
        })
    }

    handleConfirm = async () => {
        const { transactionToHandel } = this.state;

        this.setState({ confirm: true, open: false }, async () => {
            let confirmationText
            let confirmPoputTitle
            if (this.state.paymentIntegration.name === 'CampPay') {
                if (this.state.transactionVoidable) {
                    let returnVoidData = await returnVoid(this.state.campPayId)
                    if (returnVoidData.status === 204) {
                        let updatedtransactions = await updateTransaction(this.props.user, this.props.serverName, transactionToHandel.id)
                        confirmPoputTitle = 'Transaction voided successfully'
                        confirmationText = 'Succcessfully Voided $' + transactionToHandel.amount + ' for reservation Number: ' + transactionToHandel.reservation_id
                        this.getTransactions()
                    } else {
                        confirmPoputTitle = 'Error'
                        confirmationText = 'This transaction could not be voided at this time. This could be due to a network or communication error with your credit card processor. Please try again.'
                    }
                } else {
                    let refundAmt
                    if (this.state.fullRefund) {
                        refundAmt = -transactionToHandel.amount
                    } else {
                        refundAmt = -this.state.partialRefundAmt
                    }
                    let partialRefundResponse = await partialRefund(this.state.paymentIntegration.cp_merchantid, refundAmt, transactionToHandel.token)
                    console.log(partialRefundResponse)
                    if (partialRefundResponse.status === "Approved") {
                        let updatedtransactions = await updateTransaction(this.props.user, this.props.serverName, transactionToHandel.id)
                        confirmPoputTitle = 'Transaction refunded successfully'
                        confirmationText = 'Succcessfully Refunded $' + refundAmt + ' for reservation Number: ' + transactionToHandel.reservation_id
                        this.getTransactions()
                    } else {
                        confirmPoputTitle = 'Error'
                        confirmationText = 'This transaction could not be refunded at this time. This could be due to a network or communication error with your credit card processor. Please try again.'
                    }
                }
            } else {
                if (this.state.transactionVoidable) {
                    let voidResponse = await cardConnectVoid(this.props.user, "177000000077", this.state.referenceNumberToVoidorReturn)
                    if (voidResponse.resptext === "Approval") {
                        let updatedtransactions = await updateTransaction(this.props.user, this.props.serverName, transactionToHandel.id)
                        confirmPoputTitle = 'Transaction voided successfully'
                        confirmationText = 'Succcessfully Voided $' + transactionToHandel.amount + ' for reservation Number: ' + transactionToHandel.reservation_id
                        this.getTransactions()
                    } else {
                        confirmPoputTitle = 'Error'
                        confirmationText = 'This transaction could not be voided at this time. This could be due to a network or communication error with your credit card processor. Please try again.'
                    }
                } else {
                    let refundAmt
                    if (this.state.fullRefund) {
                        refundAmt = transactionToHandel.amount
                    } else {
                        refundAmt = this.state.partialRefundAmt
                    }
                    let returnResponse = await cardConnectRefund(this.props.user, "177000000077", this.state.referenceNumberToVoidorReturn, refundAmt)
                    if (returnResponse.resptext === "Approval") {
                        let updatedtransactions = await updateTransaction(this.props.user, this.props.serverName, transactionToHandel.id)
                        confirmPoputTitle = 'Transaction refunded successfully'
                        confirmationText = 'Succcessfully Refunded $' + refundAmt + ' for reservation Number: ' + transactionToHandel.id
                        this.getTransactions()
                    } else {
                        confirmPoputTitle = 'Error'
                        confirmationText = 'This transaction could not be refunded at this time. This could be due to a network or communication error with your credit card processor. Please try again.'
                    }
                }
            }
            this.setState({
                confirmationOpen: true,
                confirmationText: confirmationText,
                confirmPoputTitle: confirmPoputTitle
            })
        });
    };

    handleCancel = () => {
        this.setState({
            confirm: false,
            open: false,
            printOpen: false,
            fullRefund: null,
            partialRefund: null,
            partialRefundAmt: ''
        });
    };

    print = async (item) => {
        this.setState({
            printItem: item,
            printOpen: true
        });
    }

    goBack = () => {
        this.setState({
            printOpen: false,
        });
        this.getTransactions()
    }

    partialAmtChnage = (event) => {
        this.setState({
            partialRefundAmt: event.target.value
        })
    }

    render() {
        const { classes } = this.props;
        const itemsPerPage = 15;
        const totalPages = Math.ceil(this.state.transitions.length / itemsPerPage);
        let currentPage = this.state.currentPage || 1;

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, this.state.transitions.length);

        const reservationsOnPage = this.state.transitions.slice(startIndex, endIndex);

        let transactionsRows = reservationsOnPage.map((item) => {
            return (
                <tr key={item.id} className='rows'>
                    <td>{item.reservation_id}</td>
                    <td>{"$"}{parseFloat(item.amount).toFixed(2)}</td>
                    <td>{item.pmt_date}</td>
                    <td>{item.card_type}</td>
                    <td>{item.credit_card_no}</td>
                    <td>{item.memo}</td>
                    <td>{item.name}</td>
                    <td>
                        {item.refundable === 1
                            ?
                            <Button
                                onClick={() => this.refundVoid(item)}
                                style={{ backgroundColor: '#d4d4d4', color: 'black', padding: '2% 10% 2% 10%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}
                            >
                                Void/Refund
                            </Button>
                            : null}

                    </td>
                    <td>
                        <Button
                            onClick={() => this.print(item)}
                            style={{ backgroundColor: '#d4d4d4', color: 'black', padding: '2% 10% 2% 10%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}
                        >
                            Receipt
                        </Button>
                    </td>
                </tr>
            );
        });

        const paginationButtons = [];
        const maxButtonsToShow = 10;
        let startPage, endPage;

        if (totalPages <= maxButtonsToShow) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrentPage = Math.floor(maxButtonsToShow / 2);
            const maxPagesAfterCurrentPage = Math.ceil(maxButtonsToShow / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                startPage = 1;
                endPage = maxButtonsToShow;
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                startPage = totalPages - maxButtonsToShow + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            paginationButtons.push(
                <Button className={`paginationButton ${currentPage === i ? "activePage" : ""}`} key={i} onClick={() => this.setState({ currentPage: i })}>
                    {i}
                </Button>
            );
        }

        const previousButton = (
            <Button className='previousButton' disabled={currentPage === 1} onClick={() => this.setState({ currentPage: currentPage - 1 })}>
                <ArrowBackIosIcon />
            </Button>
        );

        const nextButton = (
            <Button className='nextButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: currentPage + 1 })}>
                <ArrowForwardIosIcon />
            </Button>
        );

        const lastButton = (
            <Button className='paginationButton' disabled={currentPage === totalPages} onClick={() => this.setState({ currentPage: totalPages })}>
                {totalPages}
            </Button>
        );

        if (this.state.printOpen) {
            return (
                <Print serverName={this.props.serverName} goBack={this.goBack} printItem={this.state.printItem} user={this.props.user} />
            );
        } else if (this.state.transitions.length >= 1 || this.state.noTransactions) {
            return (
                <Grid className='transactionsList'>
                    <ThemeProvider theme={theme}>
                        <Dialog open={this.state.open} onClose={this.handleCancel} classes={{ paper: classes.dialogPaper }}>
                            <DialogTitle>{this.state.popupTitle}</DialogTitle>
                            <DialogContent>
                                {this.state.popupText}
                                {!this.state.transactionVoidable
                                    ?
                                    <div style={{ marginTop: '5%' }}>
                                        {this.state.partialRefund
                                            ?
                                            <div style={{ display: 'flex', gap: '50px', alignItems: 'center' }}>
                                                <TextField id="partialRefundAmt" required label="Parital Amount" type="number"
                                                    value={this.state.partialRefundAmt}
                                                    onChange={this.partialAmtChnage} />
                                                <Button onClick={this.handleConfirm}
                                                    style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 7% 2% 7%', margin: '0 2% 0 0', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}
                                                >
                                                    Refund
                                                </Button>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    : null
                                }
                            </DialogContent>
                            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={this.handleCancel} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                    Cancel
                                </Button>
                                {!this.state.transactionVoidable
                                    ?
                                    <>
                                        <Button onClick={() => this.setState({ fullRefund: true, partialRefund: false, partialRefundAmt: '' }, this.handleConfirm)}
                                            style={{ backgroundColor: '#d4d4d4', color: '#000000', padding: '2% 7% 2% 7%', margin: '0 2% 0 0', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}
                                        >
                                            Full Refund
                                        </Button>
                                        <Button onClick={() => this.setState({ partialRefund: true, fullRefund: false, partialRefundAmt: '' })}
                                            style={{ backgroundColor: '#d4d4d4', color: '#000000', padding: '2% 7% 2% 7%', margin: '0 2% 0 0', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}
                                        >
                                            Partial Refund
                                        </Button>
                                    </>
                                    : <Button onClick={this.handleConfirm} style={{ backgroundColor: '#2dcd7a', color: '#000000', padding: '2% 10% 2% 10%', margin: '0 2% 0 0', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                        Confirm
                                    </Button>
                                }
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.confirmationOpen} onClose={() => this.setState({ confirmationOpen: false })} classes={{ paper: classes.printPaper }}>
                            <DialogTitle>{this.state.confirmPoputTitle}</DialogTitle>
                            <DialogContent>
                                {this.state.confirmationText}
                            </DialogContent>
                            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={() => this.setState({ confirmationOpen: false })} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Box className='infoBox'>
                            <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                            <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                        </Box>

                        <Box className='box'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={transactions} alt="Transactions" style={{ height: '40px', width: '40px' }} />
                                <Typography className='transactionstypography' style={{ marginLeft: '5%' }}>Transactions</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    value={this.state.searchBy}
                                    onChange={(e) => this.setState({ searchBy: e.target.value })}
                                    style={{ marginRight: '2%' }}
                                >
                                    <MenuItem value="id">Search By Reservation #</MenuItem>
                                    <MenuItem value="last4">Search By Last4</MenuItem>
                                </Select>
                                {this.state.searchBy === "id"
                                    ? <div>
                                        <SearchBar
                                            className='searchBox'
                                            placeholder='Search By ID'
                                            value={this.state.searchValue}
                                            onChange={(newValue) => this.setState({ searchValue: newValue })}
                                            onRequestSearch={() => this.getTransactionsByReservationNumber(this.state.searchValue)}
                                            cancelOnEscape={true}
                                            onCancelSearch={() => this.displayAllTransactions()}
                                        />
                                    </div>
                                    : <div>
                                        <SearchBar
                                            className='searchBox'
                                            placeholder='Search By Last4'
                                            value={this.state.searchValue}
                                            onChange={(newValue) => this.setState({ searchValue: newValue })}
                                            onRequestSearch={() => this.getTransactionsByLast4(this.state.searchValue)}
                                            cancelOnEscape={true}
                                            onCancelSearch={() => this.displayAllTransactions()}
                                        />
                                    </div>
                                }
                            </div>
                        </Box>
                    </ThemeProvider>

                    <Box className='tableheadingbox'>
                        <Typography className='headerTypo'>Reservation #</Typography>
                        <Typography className='headerTypo'>Amount</Typography>
                        <Typography className='headerTypo'>Payment Date</Typography>
                        <Typography className='headerTypo'>Payment Method</Typography>
                        <Typography className='headerTypo'>Last 4</Typography>
                        <Typography className='headerTypo'>Reference Number</Typography>
                        <Typography className='headerTypo'>Name On Card</Typography>
                        <Typography className='headerTypo'>Actions</Typography>
                    </Box>

                    {
                        !this.state.noTransactions
                            ?
                            <>
                                <Box className='tableBox'>
                                    <table>
                                        <tbody>
                                            {transactionsRows}
                                        </tbody>
                                    </table>
                                </Box>
                                <div className="pagination">
                                    {previousButton}
                                    {paginationButtons}
                                    ...
                                    {lastButton}
                                    {nextButton}
                                </div>
                            </>
                            :
                            <>
                                <Typography className='headerTypo' style={{ marginTop: '5%', fontSize: '1.5rem' }}>No Transactions Found</Typography>
                            </>
                    }
                </Grid >
            );
        } else if (this.state.noPastTransactions) {
            return (
                <Grid className='transactionsList'>
                    <Box className='infoBox'>
                        <Typography className='infoBarTypo'>Welcome: {this.props.serverName.charAt(0).toUpperCase() + this.props.serverName.slice(1)}</Typography>
                        <Typography className='infoBarTypo'>Username: {this.props.user}</Typography>
                    </Box>
                    <Box className='box'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={transactions} alt="Transactions" style={{ height: '40px', width: '40px' }} />
                            <Typography className='transactionstypography' style={{ marginLeft: '5%' }}>Transactions</Typography>
                        </div>
                    </Box>
                    <Typography style={{ marginTop: '2%', fontSize: '1.5rem' }}> You do not have any past transactions.</Typography>
                </Grid>
            );
        } else {
            return (
                <CircularProgress className={classes.circularProgress} />
            );
        }
    };
};

export default withStyles(useStyles)(Transactions);