import React, { useState } from "react";
import { Tabs, Tab, Box, Typography, useMediaQuery } from "@mui/material";
import { Button, Card, CardContent, Divider, Grid, } from "@material-ui/core";
import { useTheme } from "@mui/system";
function TabPanel({ children, value, index }) {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function ReservationTabs({ spaceData, bookSpace, facilities, additionalDetails, addressLine1, addressLine2, addressLine3, rules, changes, mapURL }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const SiteSpacecard = ({ space, bookSpace }) => {

        const [expanded, setExpanded] = useState(false);
        const handleToggleDetails = () => {
            setExpanded((prev) => !prev);
        };

        // const RECAPTCHA_SITE_KEY = "6LeeMM8qAAAAADdGtux8uA_B45ewNBXrTuoiZRPJ";
        // const handleBookSpace = async () => {
        //     try {
        //         if (!window.grecaptcha) {
        //             console.error("reCAPTCHA not loaded");
        //             return;
        //         }
        //         const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: "submit" });
        // bookSpace(space);
        //     } catch (error) {
        //         console.error("reCAPTCHA error:", error);
        //     }
        // };

        return (<Card>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ textAlign: "left", minHeight: "200px" }}
                >
                    <CardContent>
                        <div>
                            <h3 style={{ opacity: 0.7 }}> Space name: {space.space_name}</h3>
                            <p>
                                {space.description}
                            </p>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <p>
                                    <span style={{ fontWeight: "bold" }}> Daily:</span>{" "}
                                    <span>${parseFloat(space.daily_rate).toFixed(2)}</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: "bold" }}> Weekly:</span>{" "}
                                    <span>${parseFloat(space.weekly_rate).toFixed(2)}</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: "bold" }}> Monthly:</span>{" "}
                                    <span>${parseFloat(space.monthly_rate).toFixed(2)}</span>
                                </p>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <a
                                    onClick={handleToggleDetails}
                                    style={{
                                        fontSize: "14px",
                                        textDecoration: "underline",
                                        color: "#1976d2",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                    }}
                                >
                                    {expanded ? "Hide Details" : "View More Details"}
                                </a>
                                <Button
                                    className="remoteResButton"
                                    style={{
                                        width: "100px"
                                    }}
                                    onClick={() => { bookSpace(space) }}
                                // onClick={handleBookSpace}
                                >
                                    Book Now
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Grid>
            </Grid>
            {expanded && (
                <Grid
                    container
                    spacing={1}
                    style={{ margin: "10px", textAlign: "left" }}
                >
                    <Grid item xs={6} sm={3}>
                        <Typography>● {space.sitetype_name}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        {space.power_50a || space.power_30a || space.power_15a
                            ?
                            <Typography>● Electric ({space.power_50a ? '50A' : space.power_30a ? '30A' : '15A'})</Typography>
                            :
                            <Typography>● No Electric</Typography>
                        }
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        {space.water
                            ?
                            <Typography>● Water</Typography>
                            :
                            <Typography>● No Water</Typography>
                        }
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        {space.sewer
                            ?
                            <Typography>● Sewage</Typography>
                            :
                            <Typography>● No Sewage</Typography>
                        }
                    </Grid>
                </Grid>
            )}
        </Card>)
    }

    const SitesAndSpaces = ({ spaceData, bookSpace }) => {
        return (
            spaceData && spaceData.length > 0 ? <>
                <h2 className="tsHeading" style={{ textAlign: "left" }}>{spaceData.length} Results Found</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                        {spaceData.map((space, index) => (
                            <div key={index} style={{ marginBottom: "20px" }}>
                                <SiteSpacecard space={space} bookSpace={bookSpace} />
                            </div>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {mapURL ? (
                            <a href={mapURL} target="_blank" rel="noopener noreferrer">
                                <img src={mapURL} width="100%" alt="Map" />
                            </a>
                        ) : (
                            <img
                                src="/path-to-dummy-image.jpg"
                                width="100%"
                                alt="Park map coming soon"
                                style={{ objectFit: 'cover' }}
                            />
                        )}
                    </Grid>
                </Grid>
            </> : ""
        );
    };

    const FacilityInfo = () => {
        return (
            <Box style={{ textAlign: "left" }}>
                <Typography
                    className="tsHeading" variant="h5" style={{ marginBottom: "10px", fontWeight: "600" }}>
                    Facilities
                </Typography>
                <Typography style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {facilities}
                </Typography>

                <Divider style={{ marginTop: "20px" }} />

                <Typography
                    className="tsHeading"
                    variant="h5"
                    style={{ marginTop: "20px", marginBottom: "10px", fontWeight: "600" }}
                >
                    Getting here
                </Typography>
                <Typography>{addressLine1}</Typography>
                <Typography>{addressLine2}</Typography>
                <Typography>{addressLine3}</Typography>

                <Divider style={{ marginTop: "20px" }} />

                <Typography
                    className="tsHeading"
                    variant="h5"
                    style={{ marginTop: "20px", marginBottom: "10px", fontWeight: "600" }}
                >
                    Additional Details
                </Typography>
                <Typography style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {additionalDetails}
                </Typography>
            </Box>
        );
    };

    const RulesAndCancellation = () => {
        return (
            <Box style={{ textAlign: "left" }}>
                <Typography className="tsHeading" variant="h5" style={{ marginBottom: "10px", fontWeight: "600" }}>
                    Rules & Cancellation Policy
                </Typography>
                <Typography style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {rules}
                </Typography>
                <br />
                <Divider />
                <Typography className="tsHeading"
                    variant="h5"
                    style={{ marginTop: "20px", marginBottom: "10px", fontWeight: "600" }}
                >
                    Changes
                </Typography>
                <Typography style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {changes}
                </Typography>
            </Box>
        );
    };

    return (
        <Box sx={{ marginTop: "20px" }}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="reservation tabs"
                orientation={isSmallScreen ? "vertical" : "horizontal"}
                sx={{
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#2dcd7a",
                    },
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                }}
            >
                <Tab
                    sx={{
                        "&.Mui-selected": {
                            color: "#2dcd7a",
                        },
                        "&:hover": {
                            color: "#2dcd7a",
                            borderColor: "#2dcd7a",
                        },
                    }}
                    label="Sites/ Spaces"
                />
                <Tab
                    sx={{
                        "&.Mui-selected": {
                            color: "#2dcd7a",
                        },
                        "&:hover": {
                            color: "#2dcd7a",
                            borderColor: "#2dcd7a",
                        },
                    }}
                    label="Facility Information"
                />
                <Tab
                    sx={{
                        "&.Mui-selected": {
                            color: "#2dcd7a",
                        },
                        "&:hover": {
                            color: "#2dcd7a",
                            borderColor: "#2dcd7a",
                        },
                    }}
                    label="Rules & Cancellation"
                />
            </Tabs>

            <TabPanel value={value} index={0}>
                <SitesAndSpaces spaceData={spaceData} bookSpace={bookSpace} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FacilityInfo />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <RulesAndCancellation />
            </TabPanel>
        </Box>
    );
}

export default ReservationTabs;
