const MX_MERCHANT_BASE_URL = process.env.REACT_APP_MXM_BASE_URL
// const MX_MERCHANT_BASE_URL = 'https://sandbox.api.mxmerchant.com/checkout/v3/payment'

export const makePayment = async (merchantId, amount, cardNumber, expiryMonth, expiryYear, cvv, zipCode, address) => {

    const requestBody = {
        paymentType: 'Sale',
        authOnly: false,
        isAuth: false,
        cardAccount: {
            number: cardNumber,
            expiryMonth: expiryMonth,
            expiryYear: expiryYear,
            cvv: cvv,
            avsZip: zipCode,
            avsStreet: address
        },
        entryClass: 'WEB',
        isSettleFunds: false,
        source: 'API',
        taxExempt: false,
        surchargeLabel: 'surcharge',
        mxAdvantageEnabled: 'true',
        mxAdvantageFeeLabel: 'surcharge',
        mxAdvantageFeeType: 'percentage',
        merchantId: merchantId,
        amount: amount,
        tenderType: 'Card'
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: process.env.REACT_APP_MXM_AUTHORIZATION
            // Authorization: 'Basic dERQT3JyUGQ1MHZLalJMVzVQa0pRQXdzOm50THFucXhlSEtjSHIxTTZzdkI4Y2Q1VTJOTT0='
        },
        body: JSON.stringify(requestBody)
    };

    try {
        if (amount > 0) {
            let response = await fetch(MX_MERCHANT_BASE_URL + '?echo=true&includeCustomerMatches=true', options);
            let data = response.json();
            return data;
        } else {
            console.error('Amount less than 0');
            return null;
        }
    } catch (error) {
        console.error('Error while making payment: ', error);
        return null;
    }
}

export const getPayment = async (merchantId, referenceNumber) => {

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: process.env.REACT_APP_MXM_AUTHORIZATION
            // Authorization: 'Basic dERQT3JyUGQ1MHZLalJMVzVQa0pRQXdzOm50THFucXhlSEtjSHIxTTZzdkI4Y2Q1VTJOTT0='
        }
    };

    try {
        let response = await fetch(MX_MERCHANT_BASE_URL + '?merchantId=' + merchantId + '&includeCustomer=true&filter=' + referenceNumber, options);
        let data = response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error('Error while get payment: ', error);
        return null;
    }
}

export const returnVoid = async (id) => {

    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: process.env.REACT_APP_MXM_AUTHORIZATION
            // Authorization: 'Basic dERQT3JyUGQ1MHZLalJMVzVQa0pRQXdzOm50THFucXhlSEtjSHIxTTZzdkI4Y2Q1VTJOTT0='
        }
    };

    try {
        let response = await fetch(MX_MERCHANT_BASE_URL + '/' + id + '?force=true', options);
        let data = response;
        console.log(data);
        return data;
    } catch (error) {
        console.error('Error while voiding payment: ', error);
        return null;
    }
}

export const partialRefund = async (merchantId, amount, token) => {

    const requestBody = {
        merchantId: merchantId,
        amount: amount,
        tenderType: 'Card',
        paymentToken: token
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: process.env.REACT_APP_MXM_AUTHORIZATION
            // Authorization: 'Basic dERQT3JyUGQ1MHZLalJMVzVQa0pRQXdzOm50THFucXhlSEtjSHIxTTZzdkI4Y2Q1VTJOTT0='
        },
        body: JSON.stringify(requestBody)
    };

    try {
        let response = await fetch(MX_MERCHANT_BASE_URL + '?echo=true', options);
        let data = response.json();
        return data;
    } catch (error) {
        console.error('Error while making payment: ', error);
        return null;
    }
}