import React, { Component } from 'react';
import { Grid, Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import '../CSS/RemoteUserDetails.css';
import {
    getTaxes, makeReservation, getCardType, recordPayment, cardConnectPaymemt, addVariableCharge,
    addExtras, getIntegrations, getEmailTemplates, getEmails, sendMail, getCompanyName
} from '../utils/OpenCampground_Database_Calls'
import { makePayment } from '../utils/MxMerchant_Services'
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import GroupIcon from '@mui/icons-material/Group';
import PetsIcon from '@mui/icons-material/Pets';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import TodayIcon from '@mui/icons-material/Today';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import reservationConfirmation from '../assets/reservation_confirm.png'
import { Calendar, MapPin, CreditCard } from 'lucide-react';
import { CircularProgress } from '@material-ui/core';
import visa from '../assets/Visa.png';
import masterCard from '../assets/MasterCard.png';
import dicsover from '../assets/Discover.png';
import amex from '../assets/AmericanExpress.png';

const useStyles = theme => ({
    circularProgress: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        color: '#2dcd7a'
    },
    dialogPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px',
    }
});
const theme = createTheme({
    palette: {
        primary: {
            main: '#2dcd7a',
        },
    },
});

class RemoteUserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: true,

            //userDetails
            fname: '',
            lname: '',
            emailId: '',
            phoneNumber: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            counts: [],

            //pricing
            days: '',
            weeks: '',
            months: '',
            daysTotal: '',
            weeksTotal: '',
            monthsTotal: '',
            subTotal: '',
            taxes: [],
            totalAmount: '',
            tax: [],
            taxPercent: [],
            taxName: [],
            convenienceCharge: null,
            baseTotal: 0.0,
            selectedExtras: [],
            selectedExtraAmount: [],
            selectedExtraCount: [],
            extraId: [],
            requiredExtra: [],
            extraSubTotal: 0,
            selectedPaymentValue: 'fullPayment',
            partialTotal: 0.0,
            balance: 0.0,
            partialBase: '',
            cardNumber: '',
            cardOwner: '',
            expiryMonth: '',
            expiryYear: '',
            cvv: '',
            convenienceFees: '',

            //addOns
            start: '',
            end: '',
            type2Add: false,

            //popup
            open: false,
            popupText: '',

            //paymentIntegrations
            paymentIntegration: [],

            //email
            remoteResReceivedMailMessage: '',
            remoteReservationReceivedSubject: '',
            sender: '',
            reply: '',
            cc: '',
            bcc: '',
            paymentSuccess: false,
            paymentMethod: "",
            ConfirmationTotalAmount: "",
            message: "",
            companyName: '',
            waitForCalculations: true,
            open: false,
            useAMEX: true,
            open2: false,
            open1: false
        };
    }

    async componentDidMount() {
        let paymentIntegration = await getIntegrations(this.props.user, this.props.serverName)
        console.log("paymentIntegration: ", paymentIntegration)
        this.setState({
            paymentIntegration: paymentIntegration,
        })
        // let mailMessages = await getEmailTemplates(this.props.user, this.props.serverName)
        // let email = await getEmails(this.props.user, this.props.serverName)
        //calculate total
        let term = this.getDuration(this.props.checkIn, this.props.checkOut);

        var delayInMilliseconds = 2000;
        setTimeout(function () {
        }, delayInMilliseconds);

        let subTotal = 0.0;
        let monthsTotal = 0;
        let weeksTotal = 0;
        let daysTotal = 0;
        console.log("term: ", term)
        if (term[2] > 0) {
            if (parseFloat(this.props.monthly) > 0) {
                monthsTotal = (term[2] * parseFloat(this.props.monthly));
                subTotal += monthsTotal;
            } else {
                let totalDaysFromMonths = 0;
                for (let i = 0; i < term[2]; i++) {
                    let monthDate = new Date(this.props.viewItem.startdate);
                    monthDate.setMonth(monthDate.getMonth() + i);
                    const monthDays = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0).getDate();
                    totalDaysFromMonths += monthDays;
                }
                term[1] = term[1] + (totalDaysFromMonths / 7);
                term[2] = 0;
            }
        }
        if (term[1] > 0) {
            if (parseFloat(this.props.weekly) > 0) {
                weeksTotal = ((term[1]) * parseFloat(this.props.weekly));
                subTotal += weeksTotal;
            } else {
                term[0] = term[0] + (term[1] * 7);
                term[1] = 0
            }
        }
        if (term[0] > 0) {
            if (parseFloat(this.props.daily) > 0) {
                daysTotal = (Math.round(term[0]) * parseFloat(this.props.daily));
                subTotal += daysTotal;
            } else {
                console.log("daily is 0")
                subTotal += 0
            }
        }
        console.log("Subtotal: ", subTotal)
        console.log("type of Subtotal: ", typeof (subTotal))
        await this.calculateTaxAndTotal(subTotal, term);
        let response = await getCompanyName(this.props.user, this.props.serverName)
        this.setState({
            days: term[0],
            weeks: term[1],
            months: term[2],
            subTotal: subTotal,
            monthsTotal: monthsTotal,
            weeksTotal: weeksTotal,
            daysTotal: daysTotal,
            // paymentIntegration: paymentIntegration,
            convenienceFees: paymentIntegration?.credit_charges * 100,
            // remoteResReceivedMailMessage: mailMessages[3].body,
            // remoteReservationReceivedSubject: email.remote_res_subject,
            // sender: email.sender,
            // reply: email.reply,
            // cc: email.cc,
            // bcc: email.bcc,
            // paymentIntegration: paymentIntegration,
            companyName: response.body[0].CompanyName,
            useAMEX: paymentIntegration.useAMEX === 1 ? true : false,
            waitForCalculations: false
        })
        console.log("State: ", this.state)
        // if (this.checkAmounts(totalAmount, subTotal, convenienceFees)) {
        //     this.setState({
        //         waitForCalculations: false
        //     })
        // } else {
        //     this.calculateTaxAndTotal(subTotal, term);
        // }
        this.addRequiredExtras();
    }

    addRequiredExtras = async () => {
        if (this.props.extras) {
            const filteredExtrasData = this.props.extras.filter(extra => extra.remote_required === 1);
            console.log("remote require extras", filteredExtrasData);
            if (filteredExtrasData.length > 0) {
                filteredExtrasData.map((extra, index) => {
                    if (extra.extra_type === 0 || extra.extra_type === 4) {
                        this.addExtra('1', extra)
                    } else if (extra.extra_type === 1 || extra.extra_type === 3) {
                        this.addExtra('1', extra, '1')
                    }
                });
            }
        }
    }

    getDuration(checkIn, checkOut) {
        const startDate = new Date(checkIn);
        const endDate = new Date(checkOut);
        let months = 0, weeks = 0, days = 0;
        let tempDate = new Date(startDate);
        while (tempDate <= endDate) {
            const nextMonth = new Date(tempDate);
            nextMonth.setMonth(tempDate.getMonth() + 1);

            if (nextMonth <= endDate) {
                months++;
                tempDate = nextMonth;
            } else {
                break;
            }
        }
        const remainingDaysAfterMonths = Math.floor((endDate - tempDate) / (24 * 60 * 60 * 1000));
        weeks = Math.floor(remainingDaysAfterMonths / 7);
        days = remainingDaysAfterMonths % 7;

        return [days, weeks, months];
    }

    calculateTaxAndTotal = async (subTotal, term) => {
        let taxes = await getTaxes(this.props.user, this.props.serverName);
        let totalAmount = 0.0;
        let tax = [];
        let taxPercent = [];
        let taxName = [];
        let convenienceFees = 0.0;
        let baseTotal = 0.0
        //taxes
        for (let index = 0; index < taxes.length; index++) {
            const element = taxes[index];
            if (element.is_percent === 1) {
                if (element.apl_daily === 0 && element.apl_week === 0 && element.apl_month === 0) {
                } else if (element.apl_daily === 1) {
                    taxPercent[index] = element.percent;
                    const percent = (element.percent / 100)
                    tax[index] = (subTotal * percent).toFixed(2);
                    taxName[index] = element.name;
                }
            } else {
                let totalDays = term[0];
                if (element.apl_month === 1) {
                    if (element.monthly_charge_daily === 1) {
                        totalDays = totalDays + (term[2] * 30)
                    } else {
                        taxPercent[index] = element.amount;
                        tax[index] = (term[2] * element.amount)
                        taxName[index] = element.name;
                    }
                } else if (element.apl_week === 1) {
                    if (element.weekly_charge_daily === 1) {
                        totalDays = totalDays + (term[1] * 7)
                    } else {
                        taxPercent[index] = element.amount;
                        tax[index] = (term[1] * element.amount)
                        taxName[index] = element.name;
                    }
                }
                taxPercent[index] = element.amount;
                tax[index] = (totalDays * element.amount)
                taxName[index] = element.name;
            }
        }

        totalAmount = subTotal;
        for (let index = 0; index < tax.length; index++) {
            totalAmount = totalAmount + parseFloat(tax[index]);
        }
        baseTotal = totalAmount
        console.log("total amt: ", totalAmount)
        // console.log("this.state.paymentIntegration.name: ", this.state.paymentIntegration.name)
        console.log(" this.state.selectedPaymentValue: ", this.state.selectedPaymentValue)
        if (this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r') {
            console.log("inside if condition")
            const serviceFeesPercent = (this.state.paymentIntegration.credit_charges * 100)
            const calculatedServiceFees = (totalAmount.toFixed(2) * serviceFeesPercent)
            convenienceFees = (calculatedServiceFees / 100);
        }
        console.log("CC fees: ", convenienceFees)
        this.setState({
            taxes: taxes,
            totalAmount: totalAmount.toFixed(2),
            tax: tax,
            taxPercent: taxPercent,
            taxName: taxName,
            convenienceCharge: this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' ? convenienceFees.toFixed(2) : null,
            baseTotal: baseTotal.toFixed(2),
        })

        // if (this.checkAmounts(totalAmount, subTotal, convenienceFees)) {
        //     this.setState({
        //         waitForCalculations: false
        //     })
        // } else {
        //     this.calculateTaxAndTotal(subTotal, term);
        // }
    }

    checkAmounts = (totalAmount, subTotal, convenienceFees) => {
        return (totalAmount > 0 && subTotal > 0 && (this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' ? convenienceFees > 0 : true));
    }

    userDetailsChange = (event) => {
        if (event.target.id === 'phoneNumber') {
            const numericValue = event.target.value.replace(/[^0-9]/g, '');
            this.setState({
                [event.target.id]: numericValue
            }, () => {
                this.userDetailsValidation();
            });
        } else {
            this.setState({
                [event.target.id]: event.target.value
            }, () => {
                this.userDetailsValidation();
            })
        }
    }

    getDays = () => {
        const msPerDay = 24 * 60 * 60 * 1000;
        const startDate = new Date(this.props.checkIn);
        const endDate = new Date(this.props.checkOut);
        let totalDays = Math.floor((endDate - startDate) / msPerDay);
        return totalDays;
    }

    addExtra = (required, extra, count) => {
        let extraTotal = 0;
        let totalDays = this.getDays();
        let duration = this.getDuration(this.props.checkIn, this.props.checkOut)
        let finalCount
        let extraAmount = 0;
        if (extra.extra_type === 0) {
            let tempTotal = 0
            if (duration[2] > 0) {
                tempTotal = tempTotal + (duration[2] * extra.monthly)
            }
            if (duration[1] > 0) {
                tempTotal = tempTotal + (duration[1] * extra.weekly)
            }
            if (duration[0]) {
                tempTotal = tempTotal + (duration[0] * extra.daily);
            }
            extraTotal = this.state.subTotal + tempTotal
            finalCount = totalDays
            extraAmount = tempTotal
        } else if (extra.extra_type === 1) {
            extraTotal = this.state.subTotal + (count * totalDays * extra.daily)
            finalCount = count * totalDays
            extraAmount = (count * totalDays * extra.daily)
        } else if (extra.extra_type === 2) {
            extraTotal = this.state.subTotal + ((this.state.end - this.state.start) * extra.rate)
            finalCount = (this.state.end - this.state.start)
            extraAmount = ((this.state.end - this.state.start) * extra.rate)
        } else if (extra.extra_type === 3) {
            extraTotal = this.state.subTotal + (count * extra.charge)
            finalCount = count
            extraAmount = (count * extra.charge)
        } else {
            extraTotal = this.state.subTotal + extra.charge
            finalCount = ''
            extraAmount = extra.charge
        }
        this.calculateTaxAndTotal(extraTotal, duration);
        console.log("Inside AddExtra, changing subTotal to: ", extraTotal)
        this.setState({
            subTotal: extraTotal,
            selectedExtras: [...this.state.selectedExtras, extra.name],
            selectedExtraCount: [...this.state.selectedExtraCount, finalCount],
            selectedExtraAmount: [...this.state.selectedExtraAmount, extraAmount],
            extraSubTotal: this.state.extraSubTotal + extraAmount,
            extraId: [...this.state.extraId, extra.id],
            requiredExtra: [...this.state.requiredExtra, required]
        })
    }

    handleCountChange = (index, event) => {
        const newCounts = { ...this.state.counts, [index]: event.target.value };
        this.setState({ counts: newCounts });
    };

    handlePaymentSelect = (value) => {
        let convenienceFees = 0.0
        let partialTotal = 0.0
        let balance = 0.0
        let totalAmount = 0.0
        if (value === 'checkoutPayment') {
            this.setState({
                totalAmount: this.state.totalAmount,
                convenienceCharge: this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' ? 0.0 : null,
                selectedPaymentValue: value
            })
        } else if (value === 'partialPayment') {
            convenienceFees = ((this.state.baseTotal * this.state.convenienceFees) / 100);
            partialTotal = parseFloat(this.state.baseTotal) + parseFloat(convenienceFees.toFixed(2))
            balance = 0.0
            this.setState({
                partialBase: this.state.baseTotal,
                convenienceCharge: this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' ? convenienceFees.toFixed(2) : null,
                partialTotal: this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' ? partialTotal.toFixed(2) : parseFloat(this.state.baseTotal),
                selectedPaymentValue: value
            })
        } else if (value === 'fullPayment') {
            convenienceFees = ((this.state.baseTotal * this.state.convenienceFees) / 100);
            totalAmount = parseFloat(this.state.baseTotal)
            this.setState({
                convenienceCharge: this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' ? convenienceFees.toFixed(2) : null,
                totalAmount: parseFloat(this.state.baseTotal),
                selectedPaymentValue: value
            })
        }
    }

    amountChange = (event) => {
        let convenience = 0.0
        let totalPartialAmount = 0.0
        convenience = ((event.target.value * this.state.convenienceFees) / 100)
        totalPartialAmount = parseFloat(event.target.value) + parseFloat(convenience.toFixed(2))
        this.setState({
            partialTotal: totalPartialAmount.toFixed(2),
            convenienceCharge: this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' ? convenience.toFixed(2) : null,
            partialBase: event.target.value
        })
    }

    handlePaymentDetailsChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        }, () => {
            this.userDetailsValidation();
        })
    }

    // userDetailsValidation = () => {
    //     if (this.state.fname && this.state.lname && this.state.emailId && this.state.phoneNumber.length === 10 && this.state.address &&
    //         this.state.city && this.state.state && this.state.zip
    //         // && (this.state.cardNumber.length == 16 || this.state.cardNumber.length == 15) && this.state.cardOwner &&
    //         // this.state.expiryMonth.length == 2 && this.state.expiryYear.length == 2 && (this.state.cvv.length >= 3 && this.state.cvv.length <= 4)
    //     ) {
    //         this.setState({
    //             userDetailsFormValid: true
    //         })
    //     } else if (this.state.formValid) {
    //         this.setState({
    //             userDetailsFormValid: false
    //         })
    //     }
    // }
    userDetailsValidation = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const errors = {
            fname: !this.state.fname,
            lname: !this.state.lname,
            // emailId: !this.state.emailId,
            emailId: !this.state.emailId || !emailRegex.test(this.state.emailId),
            phoneNumber: !this.state.phoneNumber || this.state.phoneNumber.length !== 10,
            address: !this.state.address,
            city: !this.state.city,
            state: !this.state.state,
            zip: !this.state.zip
        };

        this.setState({
            errors,
            userDetailsFormValid: !Object.values(errors).includes(true)
        });
    };

    pay = async () => {
        this.setState({
            userDetailsFormValid: false,
            open2: true
        })
        let mailMessages = await getEmailTemplates(this.props.user, this.props.serverName)
        let email = await getEmails(this.props.user, this.props.serverName)
        this.setState({
            remoteResReceivedMailMessage: mailMessages[3].body,
            remoteReservationReceivedSubject: email.remote_res_subject,
            sender: email.sender,
            reply: email.reply,
            cc: email.cc,
            bcc: email.bcc,
        })
        let amount;
        let rigId;
        let discountId;
        let taxtotal = 0.0;

        //rig
        for (let index = 0; index < this.props.rigs.length; index++) {
            if (this.props.rigType === this.props.rigs[index].name) {
                rigId = this.props.rigs[index].id
            }
        }

        //tax
        for (let index = 0; index < this.state.tax.length; index++) {
            taxtotal = taxtotal + parseFloat(this.state.tax[index]);
        }

        if (this.state.selectedPaymentValue === 'fullPayment' || this.state.selectedPaymentValue === 'partialPayment') {
            amount = this.state.totalAmount
            if (this.state.selectedPaymentValue === 'fullPayment') {
                amount = this.state.totalAmount
            } else {
                amount = this.state.partialTotal
            }
            if (this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r') {
                if (this.state.selectedPaymentValue === 'fullPayment') {
                    amount = parseFloat(amount) + parseFloat(this.state.convenienceCharge)
                } else {
                    console.log("amount: ", amount)
                }
                let payment
                // if (amount == null || amount <= 0.00) {
                //     this.setState({
                //         open: true
                //     })
                // } 
                if (amount > 0.00) {
                    console.log("Making a payment of amount: ", amount)
                    console.log("this amount is total of: ")
                    console.log("tax", taxtotal)
                    console.log("convenience charge: ", parseFloat(this.state.convenienceCharge))
                    payment = await makePayment(this.state.paymentIntegration.cp_merchantid, amount, this.state.cardNumber, this.state.expiryMonth, this.state.expiryYear, this.state.cvv, this.state.zip, this.state.address)
                    // payment = await makePayment('1000017768', amount, this.state.cardNumber, this.state.expiryMonth, this.state.expiryYear, this.state.cvv, this.state.zip, this.state.address)
                    console.log("payment: ", payment)
                    if (payment.status === 'Approved') {
                        console.log("values for deposit", amount)
                        console.log("value of tax:", taxtotal)
                        console.log("total value for reservation: ", ((parseFloat(this.state.totalAmount) + parseFloat(this.state.convenienceCharge)) - parseFloat(taxtotal)))
                        let reservation = await makeReservation(this.props.user, this.props.serverName, this.state.lname, this.state.fname, this.state.address, this.state.city,
                            this.state.state, this.state.zip, this.state.phoneNumber, this.state.emailId, rigId, this.props.siteId, "", this.props.checkIn, this.props.checkOut,
                            amount, this.props.slides, this.props.length, this.props.guests, this.props.pets,
                            ((parseFloat(this.state.totalAmount) + parseFloat(this.state.convenienceCharge)) - parseFloat(taxtotal)), format(new Date(), 'yyyy-MM-dd'), taxtotal, 1);

                        console.log("Reservation Id : ", reservation.insertId)

                        let cardId = await getCardType(payment.cardAccount.cardType)

                        console.log("Recording payment: ", amount)
                        let paymentRecords = await recordPayment(this.props.user, this.props.serverName, amount, reservation.insertId, payment.created, payment.created, payment.reference, cardId,
                            payment.cardAccount.last4, this.state.cardOwner, format(new Date(), 'yyyy-MM-dd'), 1, payment.cardAccount.token)
                        console.log("payment record: ", paymentRecords)

                        console.log("adding variable charge: ", this.state.convenienceCharge)
                        let variableChargeResponse = await addVariableCharge(this.props.user, this.props.serverName, reservation.insertId, "Service Fees", this.state.convenienceCharge, new Date(), new Date())
                        console.log("variableChargeResponse: ", variableChargeResponse)

                        if (this.state.selectedExtras.length > 0) {
                            let days = this.getDays()
                            for (let index = 0; index < this.state.selectedExtras.length; index++) {
                                let extraResponse = await addExtras(this.props.user, this.props.serverName, this.state.extraId[index], reservation.insertId, this.state.counts[index], days, this.state.selectedExtraAmount[index], format(new Date(), 'yyyy-MM-dd'))
                                console.log("extraResponse: ", extraResponse)
                            }
                        }

                        let mailmessage = this.replaceMailTemplatePlaceHolders(reservation.insertId)
                        let mailResponse = await sendMail(this.props.user, this.props.serverName, this.state.sender, this.state.emailId, this.state.remoteReservationReceivedSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)

                        this.setState({
                            open2: false,
                            openConfirmation: true,
                            paymentSuccess: true,
                            confirmationReservationId: reservation.insertId,
                            paymentMethod: payment.cardAccount.cardType + " card ending in " + payment.cardAccount.last4,
                            ConfirmationTotalAmount: amount,
                            message: "Your reservation has been received. A confirmation email has been sent to " + this.state.emailId + "."
                        })
                    } else {
                        this.setState({
                            open2: false,
                            openConfirmation: true,
                            paymentSuccess: false,
                            paymentMethod: payment.cardAccount.cardType + " card ending in " + payment.cardAccount.last4,
                            ConfirmationTotalAmount: amount,
                            message: "Payment Declined. Please try again"
                        })
                    }
                } else {
                    this.setState({
                        open: true
                    })
                }
            } else {
                amount = parseFloat(amount).toFixed(2);
                let paymentResponse = await cardConnectPaymemt(this.props.user, '177000000077', amount, this.state.cardNumber, this.state.expiryMonth, this.state.expiryYear, this.state.cvv, this.state.zip, this.state.address, this.state.fname, this.state.city)
                console.log("payment: ", paymentResponse)
                if (paymentResponse.respstat === "A") {
                    let reservation = await makeReservation(this.props.user, this.props.serverName, this.state.lname, this.state.fname, this.state.address, this.state.city,
                        this.state.state, this.state.zip, this.state.phoneNumber, this.state.emailId, rigId, this.props.siteId, "", this.props.checkIn, this.props.checkOut,
                        amount, this.props.slides, this.props.length, this.props.guests, this.props.pets, (this.state.totalAmount - taxtotal), format(new Date(), 'yyyy-MM-dd'),
                        taxtotal, 1);
                    console.log("Reservation Id : ", reservation.insertId)
                    //replace hardcoded 2 with cardId
                    // let cardId = await getCardType(paymentResponse.binInfo.product)
                    let paymentRecords = await recordPayment(this.props.user, this.props.serverName, amount, reservation.insertId, format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'),
                        paymentResponse.retref, "2", paymentResponse.account.slice(-4), this.state.cardOwner, format(new Date(), 'yyyy-MM-dd'), 1, paymentResponse.token)
                    console.log("payment record: ", paymentRecords)
                    if (this.state.selectedExtras.length > 0) {
                        let days = this.getDays()
                        for (let index = 0; index < this.state.selectedExtras.length; index++) {
                            let extraResponse = await addExtras(this.props.user, this.props.serverName, this.state.extraId[index], reservation.insertId, this.state.counts[index], days, this.state.selectedExtraAmount[index], format(new Date(), 'yyyy-MM-dd'))
                            console.log("extraResponse: ", extraResponse)
                        }
                    }
                    //send Reservation created mail
                    let mailmessage = this.replaceMailTemplatePlaceHolders(reservation.insertId)
                    let mailResponse = await sendMail(this.props.user, this.props.serverName, this.state.sender, this.state.emailId, this.state.remoteReservationReceivedSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)

                    this.setState({
                        open2: false,
                        openConfirmation: true,
                        paymentSuccess: true,
                        confirmationReservationId: reservation.insertId,
                        paymentMethod: "visa card ending in " + paymentResponse.account.slice(-4),
                        ConfirmationTotalAmount: amount,
                        message: "Your reservation has been received. A confirmation email has been sent to " + this.state.emailId + "."
                    })
                } else {
                    this.setState({
                        open2: false,
                        openConfirmation: true,
                        paymentSuccess: false,
                        paymentMethod: "visa card ending in " + paymentResponse.account.slice(-4),
                        ConfirmationTotalAmount: amount,
                        message: "Payment Declined. Please try again"
                    })
                }
            }
        } else {
            let reservation = await makeReservation(this.props.user, this.props.serverName, this.state.lname, this.state.fname, this.state.address, this.state.city, this.state.state, this.state.zip, this.state.phoneNumber, this.state.emailId, rigId, this.props.siteId, 'null', this.props.checkIn, this.props.checkOut, '0', this.props.slides, this.props.rigLength, this.props.guests, this.props.pets, (this.state.totalAmount - taxtotal), format(new Date(), 'yyyy-MM-dd'), taxtotal, 1);
            console.log("Reservation Id : ", reservation.insertId)
            if (this.state.selectedExtras.length > 0) {
                let days = this.getDays()
                for (let index = 0; index < this.state.selectedExtras.length; index++) {
                    let extraResponse = await addExtras(this.props.user, this.props.serverName, this.state.extraId[index], reservation.insertId, this.state.counts[index], days, this.state.selectedExtraAmount[index], format(new Date(), 'yyyy-MM-dd'))
                    console.log("extraResponse: ", extraResponse)
                }
            }
            //send Reservation created mail
            let mailmessage = this.replaceMailTemplatePlaceHolders(reservation.insertId)
            let mailResponse = await sendMail(this.props.user, this.props.serverName, this.state.sender, this.state.emailId, this.state.remoteReservationReceivedSubject, mailmessage, this.state.reply, this.state.cc, this.state.bcc)
            this.setState({
                open2: false,
                openConfirmation: true,
                paymentSuccess: true,
                paymentMethod: "Pay at thime of CheckIn",
                ConfirmationTotalAmount: "0.00",
                confirmationReservationId: reservation.insertId,
                message: "Your reservation has been received. A confirmation email has been sent to " + this.state.emailId + "."
            })
        }
    }

    replaceMailTemplatePlaceHolders = (reservationId) => {
        let mailMessage = this.state.remoteResReceivedMailMessage
        mailMessage = mailMessage.replace('{{camper}}', this.state.fname + ' ' + this.state.lname);
        mailMessage = mailMessage.replace('{{space_name}}', this.state.fname + ' ' + this.state.lname);
        mailMessage = mailMessage.replace('{{number}}', reservationId);
        mailMessage = mailMessage.replace('{{created}}', format(new Date(), 'yyyy-MM-dd'));
        mailMessage = mailMessage.replace('{{start}}', this.props.checkIn);
        mailMessage = mailMessage.replace('{{departure}}', this.props.checkOut);
        mailMessage = mailMessage.replace('{{charges}}', this.state.totalAmount);
        mailMessage = mailMessage.replace('{{payment}}', this.state.amountPaid);
        mailMessage = mailMessage.replace('{{due}}', this.state.totalAmount - this.state.amountPaid);
        mailMessage = mailMessage.replace('{{deposit}}', this.state.amountPaid);
        console.log(mailMessage)
        return mailMessage
    }

    handleStartEnd = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        }, () => {
            this.validateAddOns();
        })
    }

    validateAddOns = () => {
        if (this.state.start > 0 && this.state.end > 0 && this.state.end > this.state.start) {
            this.setState({
                type2Add: true
            })
        } else if (this.state.type2Add) {
            this.setState({
                type2Add: false
            })
        }
    }

    deleteExtra = async (index) => {
        console.log("extra id: ", this.state.extraId[index])
        let term = this.getDuration(this.state.startdate, this.state.enddate);
        this.calculateTaxAndTotal(this.state.subTotal - this.state.selectedExtraAmount[index], term);
        let array1 = [...this.state.selectedExtras];
        let array2 = [...this.state.selectedExtraCount];
        let array3 = [...this.state.selectedExtraAmount];
        array1.splice(index, 1);
        array2.splice(index, 1);
        array3.splice(index, 1);
        console.log("Inside AddExtra, changing subTotal to: ", this.state.subTotal - this.state.selectedExtraAmount[index])
        this.setState({
            selectedExtras: array1,
            selectedExtraCount: array2,
            selectedExtraAmount: array3,
            subTotal: this.state.subTotal - this.state.selectedExtraAmount[index]
        });
    }

    handlePayClick = () => {
        this.userDetailsValidation();
        if (this.state.userDetailsFormValid) {
            this.pay();
        } else {
            this.setState({ open1: true })
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid className='remoteReservationList'>
                {this.state.waitForCalculations
                    ?
                    <>
                        <CircularProgress className={classes.circularProgress} />
                    </>
                    :
                    <>
                        <ThemeProvider theme={theme} classes={{ paper: classes.dialogPaper }}>
                            <Dialog open={this.state.open} onClose={() => { this.setState({ open: false }) }} classes={{ paper: classes.dialogPaper }}>
                                <DialogTitle>Invalid Amount</DialogTitle>
                                <DialogContent>
                                    Amount should be greater than $0
                                </DialogContent>
                                <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button onClick={() => { this.setState({ open: false }) }} style={{ backgroundColor: '#2dcd7a', color: '#ffffff', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog open={this.state.open1} onClose={() => { this.setState({ open: false }) }} classes={{ paper: classes.dialogPaper }}>
                                <DialogTitle>Required Fields Missing</DialogTitle>
                                <DialogContent>
                                    Please fill in all required fields before proceeding with payment.
                                </DialogContent>
                                <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={() => { this.setState({ open1: false }) }} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%' }}>
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog open={this.state.open2} onClose={() => this.setState({ open2: false })} classes={{ paper: classes.dialogPaper }}>
                                <DialogTitle>Processing Your Payment</DialogTitle>
                                <DialogContent>
                                    Please do not close this window or click back while we process your payment.
                                </DialogContent>
                            </Dialog>

                            <Dialog open={this.state.openConfirmation} onClose={() => this.setState({ openConfirmation: false })} maxWidth="sm" fullWidth>
                                <DialogContent>
                                    <DialogContent>
                                        <div style={{ padding: '24px' }}>
                                            <div
                                                style={{
                                                    backgroundColor: '#D1FAE5',
                                                    color: this.state.paymentSuccess ? '#047857' : '#047857',
                                                    borderRadius: '8px',
                                                    padding: '16px',
                                                    marginBottom: '24px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                <span style={{ fontWeight: '600', display: 'block' }}>{this.state.paymentSuccess ? "Successful" : "Declined"}</span>
                                                {this.state.message}
                                            </div>

                                            <div
                                                style={{
                                                    backgroundColor: '#FFFFFF',
                                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                                    borderRadius: '8px',
                                                    padding: '24px',
                                                    border: '1px solid #E5E7EB',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        paddingBottom: '16px',
                                                        borderBottom: '1px solid #E5E7EB',
                                                    }}
                                                >
                                                    <span style={{ color: '#6B7280', fontSize: '14px', fontWeight: '500' }}>
                                                        Confirmation Number
                                                    </span>
                                                    <span style={{ fontWeight: '600', color: '#111827' }}>{this.state.confirmationReservationId}</span>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '12px',
                                                        color: '#374151',
                                                        paddingTop: '16px',
                                                    }}
                                                >
                                                    <Calendar style={{ height: '24px', width: '24px', color: '#6B7280' }} />
                                                    <div>
                                                        <p style={{ fontWeight: '500', color: '#111827' }}>Check-in & Check-out</p>
                                                        <p style={{ fontSize: '14px', color: '#6B7280' }}>{this.props.checkIn} / {this.props.checkOut}</p>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '12px',
                                                        color: '#374151',
                                                        paddingTop: '16px',
                                                    }}
                                                >
                                                    <MapPin style={{ height: '24px', width: '24px', color: '#6B7280' }} />
                                                    <div>
                                                        <p style={{ fontWeight: '500', color: '#111827' }}>Location</p>
                                                        <p style={{ fontSize: '14px', color: '#6B7280' }}>{this.state.companyName}</p>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '12px',
                                                        color: '#374151',
                                                        paddingTop: '16px',
                                                    }}
                                                >
                                                    <CreditCard style={{ height: '24px', width: '24px', color: '#6B7280' }} />
                                                    <div>
                                                        <p style={{ fontWeight: '500', color: '#111827' }}>Payment Method</p>
                                                        <p style={{ fontSize: '14px', color: '#6B7280' }}>{this.state.paymentMethod}</p>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        paddingTop: '16px',
                                                        marginTop: '24px',
                                                        borderTop: '1px solid #E5E7EB',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <span style={{ fontWeight: '500', color: '#111827' }}>Total Amount Paid</span>
                                                        <span style={{ fontSize: '20px', fontWeight: '600', color: '#111827' }}>
                                                            ${parseFloat(this.state.ConfirmationTotalAmount).toFixed(2)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DialogContent>
                                </DialogContent>
                                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button onClick={() => window.location.href = 'https://www.google.com/'} style={{ backgroundColor: '#FDECEB', color: '#DB3737', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                        Close
                                    </Button>
                                    <Button onClick={() => window.location.reload()} style={{ backgroundColor: '#EAFAE8', color: '#01AE00', padding: '2% 10% 2% 10%', margin: '0 0 0 2%', borderRadius: '10px', marginTop: '3%', textTransform: "capitalize" }}>
                                        Make Another Reservation
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </ThemeProvider>

                        {this.state.userDetails
                            ?
                            <>
                                <Box className='box'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography className='reservationstypography' style={{ marginBottom: "2%", marginLeft: "2%" }}><img src={reservationConfirmation} alt="Reservations" style={{ height: '40px' }} />Confirm Reservation</Typography>

                                    </div>
                                    <div>
                                        <Button startIcon={<ArrowBackIcon />} className='cancel' style={{ marginRight: '2%', backgroundColor: "rgb(153, 153, 153)", color: "#FFF", whiteSpace: "nowrap" }} onClick={() => {
                                            this.props.backToSelectSpace();
                                        }}><span className='backToSpace'>Select Space</span></Button>

                                    </div>
                                </Box>
                                <Box className='main'>
                                    <Box className='leftRow'>
                                        <Box className='userDetails'>
                                            <div style={{ display: 'flex' }}>
                                                <Typography className='typo'>User Details</Typography>
                                                <Typography className='typo' style={{ fontSize: '1rem', color: 'red', marginTop: '1%' }}>(* All fields are required)</Typography>
                                            </div>
                                            <hr className='horizontalLine' />
                                            <ThemeProvider theme={theme}>
                                                <div className='form-row'>
                                                    <TextField id="fname" required label="First Name" type="text" className='field' onChange={this.userDetailsChange}
                                                        error={this.state.errors?.fname}
                                                        helperText={this.state.errors?.fname ? "First Name is required" : ""}
                                                    />
                                                    <TextField id="lname" required label="Last Name" type="text" className='field' onChange={this.userDetailsChange}
                                                        error={this.state.errors?.lname}
                                                        helperText={this.state.errors?.lname ? "Last Name is required" : ""}
                                                    />
                                                </div>

                                                <div className='form-row'>
                                                    <TextField id="emailId" required label="Email Id" type="email" className='field' onChange={this.userDetailsChange}
                                                        error={this.state.errors?.emailId}
                                                        helperText={this.state.errors?.emailId ? "Enter a valid email (e.g., user@example.com)" : ""}
                                                    />
                                                    <TextField id="phoneNumber" required label="Phone Number" type="text" className='field' onChange={this.userDetailsChange}
                                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                        error={this.state.errors?.phoneNumber}
                                                        helperText={this.state.errors?.phoneNumber ? "Phone Number must be 10 digits" : ""}
                                                    />
                                                </div>

                                                <div className='form-row-2'>
                                                    <TextField id="address" required label="Address" type="text" className='full-width' onChange={this.userDetailsChange}
                                                        error={this.state.errors?.address}
                                                        helperText={this.state.errors?.address ? "Address is required" : ""}
                                                    />
                                                </div>
                                                <div className='form-row-3'>
                                                    <TextField id="city" required label="City" type="text" className='left' onChange={this.userDetailsChange}
                                                        error={this.state.errors?.city}
                                                        helperText={this.state.errors?.city ? "City is required" : ""}
                                                    />
                                                    <TextField id="state" required label="State" type="text" className='middle' onChange={this.userDetailsChange}
                                                        error={this.state.errors?.state}
                                                        helperText={this.state.errors?.state ? "State is required" : ""}
                                                    />
                                                    <TextField id="zip" required label="Postal Code" type="text" className='right' onChange={this.userDetailsChange}
                                                        error={this.state.errors?.zip}
                                                        helperText={this.state.errors?.zip ? "Postal Code is required" : ""}
                                                    />
                                                </div>
                                            </ThemeProvider>
                                        </Box>

                                        {this.state.selectedExtras.length > 0
                                            ?
                                            <Box className='addOns'>
                                                <Typography className='typo'>Extras </Typography>
                                                <hr className='horizontalLine' />
                                                <ThemeProvider theme={theme}>
                                                    <Box className='addOnsForm'>
                                                        <Typography>Extra Name</Typography>
                                                        <Typography>Count</Typography>
                                                        <Typography>Total</Typography>
                                                    </Box>
                                                    {this.state.selectedExtras.map((extra, index) => (
                                                        <Box key={index} className='addOnsForm'>
                                                            <Typography>{this.state.selectedExtras[index]}</Typography>
                                                            <Typography>{this.state.selectedExtraCount[index]}</Typography>
                                                            <Typography>${parseFloat(this.state.selectedExtraAmount[index]).toFixed(2)}</Typography>
                                                            {this.state.requiredExtra[index] === '1'
                                                                ? null
                                                                :
                                                                <Button
                                                                    style={{ backgroundColor: '#FDECEB', color: '#DB3737', borderRadius: '5px', width: '80%', marginTop: '-2%' }}
                                                                    onClick={() => this.deleteExtra(index)}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            }
                                                        </Box>
                                                    ))}
                                                </ThemeProvider>
                                            </Box>
                                            : null
                                        }

                                        <Box className='addOns'>
                                            <Typography className='typo'>Add-Ons</Typography>
                                            <hr className='horizontalLine' />
                                            <ThemeProvider theme={theme}>
                                                {this.props.extras
                                                    .filter(extra => extra.remote_display === 1)
                                                    .map((extra, index) => (
                                                        <Box key={index} className='addOnsForm'>
                                                            {extra.extra_type === 0 || extra.extra_type === 4
                                                                ?
                                                                <>
                                                                    <Typography className='bookingLabel'>{extra.name}</Typography>
                                                                    <Button
                                                                        className='addButton'
                                                                        onClick={() => this.addExtra('0', extra)}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </>
                                                                : null
                                                            }
                                                            {extra.extra_type === 1 || extra.extra_type === 3
                                                                ?
                                                                <>
                                                                    <Typography className='bookingLabel'>{extra.name}</Typography>
                                                                    <TextField
                                                                        required
                                                                        label="Count"
                                                                        type="number"
                                                                        className='textfields'
                                                                        value={this.state.counts[index] || ''}
                                                                        onChange={(event) => this.handleCountChange(index, event)}
                                                                    />
                                                                    <Button
                                                                        className='addButton'
                                                                        onClick={() => this.addExtra('0', extra, this.state.counts[index])}
                                                                        disabled={!(this.state.counts[index] && this.state.counts[index] > 0)}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </>
                                                                : null
                                                            }
                                                        </Box>
                                                    ))}
                                            </ThemeProvider>
                                        </Box>

                                        <Box className='payment'>
                                            <Typography className='typo'>Payment Information</Typography>
                                            <hr className='horizontalLine' />
                                            <ThemeProvider theme={theme}>
                                                <Box className='paymentOptionWrapper'>
                                                    {this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' || this.state.paymentIntegration.name === 'CardConnect' || this.state.paymentIntegration.name === 'CardConnect_r'
                                                        ?
                                                        <>
                                                            {this.props.require
                                                                ?
                                                                <>
                                                                    <Box className={`paymentOption ${this.state.selectedPaymentValue === "fullPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("fullPayment") }}>
                                                                        Full Payment
                                                                    </Box>
                                                                </>
                                                                : null
                                                            }

                                                            {/* remote payment allowed */}
                                                            {this.props.allow
                                                                ?
                                                                <>
                                                                    <Box className={`paymentOption ${this.state.selectedPaymentValue === "fullPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("fullPayment") }}>
                                                                        Full Payment
                                                                    </Box>
                                                                    <Box className={`paymentOption ${this.state.selectedPaymentValue === "checkoutPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("checkoutPayment") }}>
                                                                        Pay at time of checkIn
                                                                    </Box>
                                                                </>
                                                                : null
                                                            }

                                                            {!this.props.allow && !this.props.require
                                                                ?
                                                                <>
                                                                    <Box className={`paymentOption ${this.state.selectedPaymentValue === "checkoutPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("checkoutPayment") }}>
                                                                        Pay at time of CheckIn
                                                                    </Box>
                                                                </>
                                                                : null
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <Box className={`paymentOption ${this.state.selectedPaymentValue === "checkoutPayment" ? "activePaymentOption" : ""}`} onClick={() => { this.handlePaymentSelect("checkoutPayment") }}>
                                                                Pay at time of CheckIn
                                                            </Box>
                                                        </>
                                                    }
                                                </Box>
                                                {this.state.selectedPaymentValue === 'fullPayment' || this.state.selectedPaymentValue === 'partialPayment'
                                                    ? (
                                                        <>
                                                            {this.state.selectedPaymentValue === 'fullPayment'
                                                                ? (
                                                                    <>
                                                                        {this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r'
                                                                            ?
                                                                            <>
                                                                                <div className='rows'>
                                                                                    <Typography className='headings'>Amount</Typography>
                                                                                    <TextField id="paymentAmt" required type="number" variant='standard'
                                                                                        className='resRight' value={this.state.totalAmount} disabled={true} />
                                                                                </div>
                                                                                <div className='rows'>
                                                                                    <Typography className='headings'>Service fees</Typography>
                                                                                    <TextField id="paymentAmt" type="number"
                                                                                        className='resRight' value={parseFloat(this.state.convenienceCharge).toFixed(2)} disabled={true} variant='standard' />
                                                                                </div>
                                                                                <div className='rows'>
                                                                                    <Typography className='headings'>Total</Typography>
                                                                                    <TextField id="paymentAmt" required type="number" className='resRight' disabled={true} variant='standard'
                                                                                        value={(parseFloat(this.state.totalAmount) + parseFloat(this.state.convenienceCharge)).toFixed(2)}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className='rows'>
                                                                                    <Typography className='headings'>Total</Typography>
                                                                                    <TextField id="paymentAmt" required type="number" className='resRight' disabled={true} variant='standard'
                                                                                        value={parseFloat(this.state.totalAmount).toFixed(2)}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        }

                                                                    </>
                                                                )
                                                                : (
                                                                    <>
                                                                        {this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r'
                                                                            ?
                                                                            <>
                                                                                <div className='rows'>
                                                                                    <Typography className='headings'>Amount to be paid</Typography>
                                                                                    <TextField id="paymentAmt" required label="Amount" type="number"
                                                                                        className='resRight' value={this.state.partialBase} onChange={this.amountChange} />
                                                                                </div>
                                                                                <div className='rows'>
                                                                                    <Typography className='headings'>Convenience fees</Typography>
                                                                                    <TextField id="paymentAmt" type="number"
                                                                                        className='resRight' value={this.state.convenienceCharge} disabled={true} variant='standard' />
                                                                                </div>
                                                                                <div className='rows'>
                                                                                    <Typography className='headings'>Total</Typography>
                                                                                    <TextField id="paymentAmt" type="number"
                                                                                        className='resRight' value={this.state.partialTotal} disabled={true} variant='standard' />
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className='rows'>
                                                                                    <Typography className='headings'>Amount to be paid</Typography>
                                                                                    <TextField id="paymentAmt" required label="Amount" type="number"
                                                                                        className='resRight' value={this.state.partialBase} onChange={this.amountChange} />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            <Typography className='typo'>Card Details</Typography>
                                                            <hr className='horizontalLine' />
                                                            <Box className='cardDetailsBox' style={{ marginBottom: "50px" }}>
                                                                <Box className='cardNumberName' style={{ display: 'flex', width: "85%" }}>
                                                                    <div className='row'>
                                                                        <Typography className='typo'>Card Number</Typography>
                                                                        <TextField
                                                                            InputProps={{
                                                                                startAdornment: <CreditCardIcon />
                                                                            }}
                                                                            id="cardNumber" required label="Card Number" type="number"
                                                                            className='resRight' value={this.state.cardNumber} onChange={this.handlePaymentDetailsChange} />
                                                                    </div>

                                                                    <div className='row'>
                                                                        <Typography className='typo'>Card Owner</Typography>
                                                                        <TextField InputProps={{
                                                                            startAdornment: <PersonIcon />
                                                                        }} id="cardOwner" required label="Card Owner" type="text"
                                                                            className='resRight' value={this.state.cardOwner} onChange={this.handlePaymentDetailsChange} />
                                                                    </div>
                                                                </Box>
                                                                <Box className='cardDetails' style={{ display: 'flex', marginTop: "30px" }}>
                                                                    <div className='expiryInputRow'>
                                                                        <Typography className='typo'>Expiration Date</Typography>
                                                                        <Box style={{ display: 'flex' }}>
                                                                            <TextField style={{ width: "150px" }} InputProps={{
                                                                                startAdornment: <TodayIcon />
                                                                            }} id="expiryMonth" required label="MM" type="number" placeholder="MM"
                                                                                className='expiryInput' value={this.state.expiryMonth} onChange={this.handlePaymentDetailsChange} />
                                                                            <TextField style={{ width: "150px" }} InputProps={{
                                                                                startAdornment: <DateRangeIcon />
                                                                            }} id="expiryYear" required label="YY" type="number" placeholder="YY"
                                                                                className='expiryInputYY' value={this.state.expiryYear} onChange={this.handlePaymentDetailsChange} />
                                                                        </Box>
                                                                    </div>
                                                                    <div className='row cvv'>
                                                                        <Typography className='typo'>CVV</Typography>
                                                                        <TextField style={{ width: "150px" }} InputProps={{
                                                                            startAdornment: <KeyIcon />
                                                                        }} id="cvv" required label="CVV" type="number"
                                                                            className='cvvInput' value={this.state.cvv} onChange={this.handlePaymentDetailsChange} />
                                                                    </div>
                                                                </Box>
                                                            </Box>

                                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-1%' }}>
                                                                <Button
                                                                    className='addButton' onClick={this.handlePayClick}
                                                                // onClick={this.pay}
                                                                // disabled={!this.state.userDetailsFormValid}
                                                                >Pay</Button>
                                                            </div>
                                                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2%', paddingBottom: '2%' }}>
                                                                By making this payment, you accept OpenCampground.com
                                                                <a href="https://opencampground.com/terms-and-conditions/" target='_blank'>
                                                                    Terms and Conditions
                                                                </a>
                                                            </div>
                                                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2%', paddingBottom: '2%' }}>
                                                                <img src={visa} alt="Visa" style={{ width: '50px', height: '30px', border: '1px solid' }} />
                                                                <img src={masterCard} alt="Master Card" style={{ width: '50px', height: '30px', border: '1px solid' }} />
                                                                <img src={dicsover} alt="Discover" style={{ width: '50px', height: '30px', border: '1px solid' }} />
                                                                {this.state.useAMEX
                                                                    ?
                                                                    <img src={amex} alt="American Express" style={{ width: '50px', height: '30px', border: '1px solid' }} />
                                                                    : null
                                                                }
                                                            </div>
                                                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2%', paddingBottom: '2%' }}>
                                                                Powered By OpenCampground
                                                            </div>
                                                        </>
                                                    )
                                                    : <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Button
                                                            className='addButton'
                                                            onClick={this.pay}
                                                            style={{ whiteSpace: "nowrap", width: "200px" }}
                                                        // disabled={!this.state.userDetailsFormValid}
                                                        >
                                                            Complete Reservation
                                                        </Button>
                                                    </div>
                                                }
                                            </ThemeProvider>
                                        </Box>
                                    </Box>
                                    <Box className='rightRow'>
                                        <Box className='bookingDetails'>
                                            <Typography className='bookingTypo'>Booking Details</Typography>
                                            <hr className='horizontalLine' />
                                            <Box className='bookingRow'>
                                                <Box>
                                                    <DateRangeIcon />
                                                    <Typography className='bookingLabel'>{this.props.checkIn}</Typography>
                                                </Box>
                                                <TrendingFlatIcon />
                                                <Box className='checkout'>
                                                    <DateRangeIcon />
                                                    <Typography className='bookingValue'>{this.props.checkOut}</Typography>
                                                </Box>
                                            </Box>
                                            <Box className='bookingRow'>
                                                <Box>
                                                    <GroupIcon />
                                                    <Typography className='bookingLabel'>Guests</Typography>
                                                </Box>
                                                <Typography className='bookingValue'>{this.props.guests}</Typography>
                                            </Box>
                                            <Box className='bookingRow'>
                                                <Box>
                                                    <GroupIcon />
                                                    <Typography className='bookingLabel'>Children</Typography>
                                                </Box>
                                                <Typography className='bookingValue'>{this.props.children}</Typography>
                                            </Box>
                                            <Box className='bookingRow'>
                                                <Box>
                                                    <PetsIcon />
                                                    <Typography className='bookingLabel'>Pets</Typography>
                                                </Box>
                                                <Typography className='bookingValue'>{this.props.pets}</Typography>
                                            </Box>
                                            <hr className='horizontalLine' />
                                            <Box className='bookingRow'>
                                                <Typography className='bookingLabel'>Site Name</Typography>
                                                <Typography className='bookingValue'>{this.props.spaceName}</Typography>
                                            </Box>
                                            <Box className='bookingRow'>
                                                <Typography className='bookingLabel'>Site Type</Typography>
                                                <Typography className='bookingValue'>{this.props.siteType}</Typography>
                                            </Box>
                                            {this.state.months > 0
                                                ? <Box className='bookingRow'>
                                                    <Typography className='bookingLabel'>Months Total ({this.state.months})</Typography>
                                                    <Typography className='bookingValue'>${parseFloat(this.state.monthsTotal).toFixed(2)}</Typography>
                                                </Box>
                                                : null
                                            }
                                            {this.state.weeks > 0
                                                ? <Box className='bookingRow'>
                                                    <Typography className='bookingLabel'>Weeks Total ({this.state.weeks})</Typography>
                                                    <Typography className='bookingValue'>${parseFloat(this.state.weeksTotal).toFixed(2)}</Typography>
                                                </Box>
                                                : null
                                            }
                                            {this.state.days > 0
                                                ? <Box className='bookingRow'>
                                                    <Typography className='bookingLabel'>Days Total ({this.state.days})</Typography>
                                                    <Typography className='bookingValue'>${parseFloat(this.state.daysTotal).toFixed(2)}</Typography>
                                                </Box>
                                                : null
                                            }
                                            {Array.isArray(this.state.selectedExtras) && this.state.selectedExtras.length > 0
                                                ? <>
                                                    {this.state.selectedExtras.map((extraName, index) => (
                                                        <Box className='bookingRow' key={index}>
                                                            <Typography className='bookingLabel'>{extraName} ({this.state.selectedExtraCount[index]})</Typography>
                                                            <Typography className='bookingValue'>${parseFloat(this.state.selectedExtraAmount[index]).toFixed(2)}</Typography>
                                                        </Box>
                                                    ))}
                                                </>
                                                : null
                                            }
                                            <hr className='horizontalLine' />
                                            <Box className='bookingRow'>
                                                <Typography className='bookingLabel' style={{ fontWeight: 'bold' }}>{this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' ? 'Gross Total' : 'Sub Total'}</Typography>
                                                <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${parseFloat(this.state.subTotal).toFixed(2)}</Typography>
                                            </Box>
                                            <hr className='horizontalLine' />
                                            {this.state.taxName.map((taxName, index) => (
                                                <Box className='bookingRow' key={index}>
                                                    <Typography className='bookingLabel'>{taxName} ({this.state.taxPercent[index]}%)</Typography>
                                                    <Typography className='bookingValue'>${parseFloat(this.state.tax[index]).toFixed(2)}</Typography>
                                                </Box>
                                            ))}
                                            <hr className='horizontalLine' />

                                            <Box className='bookingRow'>
                                                <Typography className='bookingLabel' style={{ fontWeight: 'bold' }}>{this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r' ? 'Net Total' : 'Total'}</Typography>
                                                <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${parseFloat(this.state.totalAmount).toFixed(2)}</Typography>
                                            </Box>
                                            {this.state.paymentIntegration.name === 'CampPay' || this.state.paymentIntegration.name === 'CampPay_r'
                                                ?
                                                <>
                                                    <hr className='horizontalLine' />
                                                    <Box className='bookingRow'>
                                                        <Typography className='bookingLabel' >Service Fees</Typography>
                                                        <Typography className='bookingValue' >${parseFloat(this.state.convenienceCharge).toFixed(2)}</Typography>
                                                    </Box>

                                                    <hr className='horizontalLine' />
                                                    <Box className='bookingRow'>
                                                        <Typography className='bookingLabel' style={{ fontWeight: 'bold' }}>Total</Typography>
                                                        <Typography className='bookingValue' style={{ fontWeight: 'bold' }}>${(parseFloat(this.state.totalAmount) + parseFloat(this.state.convenienceCharge)).toFixed(2)}</Typography>
                                                    </Box>
                                                </>
                                                : null
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                            : null
                        }
                    </>
                }
            </Grid>
        )
    };
};

export default withStyles(useStyles)(RemoteUserDetails);